
import { mapGetters } from 'vuex'

import { mixin as clickaway } from 'vue-clickaway'

// import 'vue-slider-component/dist-css/vue-slider-component.css'
import VueSlider from 'vue-slider-component/dist-css/vue-slider-component.umd.min'

import Product from '@/components/product/Product'
import Trigger from '@/components/category/Trigger'
import Breadcrumbs from '@/components/Breadcrumbs'

export default {
  components: {
    Product,
    Trigger,
    VueSlider,
    Breadcrumbs
  },

  mixins: [
    clickaway
  ],

  props: {
    promo: {
      type: Object,
      default () {
        return {}
      },
      required: true
    }
  },

  data () {
    return {
      sort: {
        by: null,
        active: false,
        options: {
          popularity: {
            id: 1,
            translation: 'popularity',
            value: 'popularity',
            icon: ['fas', 'fire']
          },
          priceasc: {
            id: 2,
            translation: 'price.asc',
            value: 'priceasc',
            icon: ['fas', 'arrow-down-1-9']
          },
          pricedesc: {
            id: 3,
            translation: 'price.desc',
            value: 'pricedesc',
            icon: ['fas', 'arrow-down-9-1']
          }
        }
      },
      categoryFilterKey: 'category',
      priceFilterKey: 'price',
      sortFilterKey: 'sort',
      chosenFilterValuesTitle: '',
      priceInputChanged: false,
      page: 1,
      loading: false,
      filtering: false,
      params: '',
      filters: [],
      chosenFilters: [],
      filtersTouchScreen: true,
      filtersTouchScreenActive: false,
      reachedLastPage: false,
      breadcrumbs: [
        {
          title: this.$t('pages.index.title'),
          to: this.localePath('index'),
          active: false
        },
        {
          title: this.promo.title,
          to: '#',
          active: true
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      country: 'country',
      currency: 'currency'
    }),

    chosenFilterValues () {
      return this.chosenFilters.map(filter => filter.values).flat()
    },

    chosenFiltersLength () {
      return this.chosenFilterValues.length
    }
  },

  created () {
    if (this.$route.params.params) {
      this.params = this.$route.params.params
      const categories = Object.values(this.promo.categories).flat()

      this.params.split('_').forEach((param) => {
        const values = param.split('--')
        const filterSlug = values.shift()

        if (filterSlug === this.categoryFilterKey) {
          const filter = {
            key: filterSlug,
            values
          }

          this.filters.push(filter)

          const chosenFilter = {
            slug: this.categoryFilterKey,
            title: `${this.$t('pages.promo.category')}:`,
            values: []
          }

          values.forEach((slug, index, values) => {
            const categoryIndex = categories.findIndex(c => c.slug === slug)

            if (categoryIndex !== -1) {
              const category = categories[categoryIndex]

              chosenFilter.values.push({
                slug,
                title: category.title
              })

              category.active = true

              this.chosenFilterValuesTitle += `${category.title}${index !== values.length - 1 ? ', ' : ''}`
            }
          })

          this.chosenFilters.push({ ...filter, ...chosenFilter })
        }

        if (filterSlug === this.priceFilterKey) {
          const filter = {
            key: filterSlug,
            values: [
              values.join('--')
            ]
          }

          this.filters.push(filter)

          const chosenFilter = {
            slug: this.priceFilterKey,
            title: `${this.$t('product.filter.price.title', { currency: this.currency.sign })}:`,
            values: [
              {
                slug: values.join('--'),
                title: values.join('—')
              }
            ]
          }

          this.chosenFilterValuesTitle += ` ${this.$t('pages.promo.price', { from: values[0], to: values[1], currency: this.currency.sign })}`

          this.chosenFilters.push({ ...filter, ...chosenFilter })
        }

        if (filterSlug === this.sortFilterKey) {
          const filter = {
            key: filterSlug,
            values
          }

          this.filters.push(filter)

          this.sort.by = this.sort.options[values[0]]

          const chosenFilter = {
            slug: this.sortFilterKey,
            title: '',
            values: [
              {
                slug: values.join(' '),
                title: this.$t('category.sort.by', { by: this.$t(`category.sort.options.${this.sort.options[filter.values].translation}`) })
              }
            ]
          }

          this.chosenFilters.push({ ...filter, ...chosenFilter })
        }
      })
    }

    const findIndex = this.filters.findIndex(f => f.key === 'sort')

    if (findIndex === -1) {
      this.sort.by = this.sort.options[Object.keys(this.sort.options)[0]]
    }

    this.page = this.$route.query.page ? Number(this.$route.query.page) : 1
  },

  beforeMount () {
    this.resizeWindow()
  },

  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.resizeWindow, { passive: true })
    })
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.resizeWindow)
  },

  methods: {
    chosenFilterComponent (ref) {
      if (this.$refs[ref] === undefined) {
        return false
      }

      return this.$refs[ref][0].clientWidth < this.$refs[ref][0].scrollWidth
    },

    isTouchScreen () {
      return document.documentElement.clientWidth <= 991
    },

    resizeWindow () {
      this.filtersTouchScreen = this.isTouchScreen()
    },

    changeFiltersArray (characteristic, value) {
      const index = this.filters.findIndex(f => f.key === characteristic)

      if (index !== -1 && value.active) {
        this.filters[index].values.push(value.slug)
      } else if (index !== -1 && !value.active) {
        this.filters[index].values.splice(value.slug, 1)

        if (this.filters[index].values.length === 0) {
          this.filters.splice(index, 1)
        }
      } else {
        this.filters.push({
          key: characteristic,
          values: [
            value.slug
          ]
        })
      }

      this.applyFilters()
    },

    priceInputKeydown (e) {
      if (e.keyCode === 13/* enter key */) {
        this.applyPriceInput()
      } else if ([8, 38, 40, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57].includes(e.keyCode)) {
        /* numbers, arrows, del, backspace keys */
        this.priceInputChanged = true
      }
    },

    applyPriceInput () {
      this.priceInputChanged = false

      this.promo.prices.range[0] = this.$refs['min-price-input'].value
      this.promo.prices.range[1] = this.$refs['max-price-input'].value

      const range = [
        parseInt(this.promo.prices.range[0]),
        parseInt(this.promo.prices.range[1])
      ]

      if (range[0] < this.promo.prices.min) {
        range[0] = this.promo.prices.min
      }

      if (range[0] > this.promo.prices.max) {
        range[0] = this.promo.prices.max
      }

      if (range[1] > this.promo.prices.max) {
        range[1] = this.promo.prices.max
      }

      if (range[0] > range[1]) {
        range[1] = range[0]
      }

      this.promo.prices.range = range

      this.$refs['min-price-input'].value = this.promo.prices.range[0]
      this.$refs['max-price-input'].value = this.promo.prices.range[1]

      this.changePriceRange()
    },

    changePriceRange () {
      const index = this.filters.findIndex(f => f.key === this.priceFilterKey)

      const filter = {
        key: this.priceFilterKey,
        values: [
          parseInt(this.promo.prices.range[0]),
          parseInt(this.promo.prices.range[1])
        ]
      }

      if (index !== -1) {
        this.filters[index] = filter
      } else {
        this.filters.push(filter)
      }

      this.applyFilters()
    },

    changeSort (by) {
      this.sort.by = by

      const index = this.filters.findIndex(f => f.key === this.sortFilterKey)

      const filter = {
        key: this.sortFilterKey,
        values: [
          this.sort.by.value
        ]
      }

      const defaultSortKey = Object.keys(this.sort.options)[0]

      if (index !== -1 && this.sort.by.value !== defaultSortKey) {
        this.filters[index] = filter
      } else if (index === -1 && this.sort.by.value !== defaultSortKey) {
        this.filters.push(filter)
      } else if (index !== -1 && this.sort.by.value === defaultSortKey) {
        this.filters.splice(index, 1)
      }

      this.applyFilters()
    },

    applyFilters () {
      this.params = ''

      const priceFilterIndex = this.filters.findIndex(f => f.key === this.priceFilterKey)

      // Now move price at the end of the array
      if (priceFilterIndex !== -1) {
        this.filters.push(this.filters.splice(priceFilterIndex, 1)[0])
      }

      const sortFilterIndex = this.filters.findIndex(f => f.key === this.sortFilterKey)

      // Now move sort at the end of the array
      if (sortFilterIndex !== -1) {
        this.filters.push(this.filters.splice(sortFilterIndex, 1)[0])
      }

      this.filters.forEach((filter, index, filters) => {
        let filterValues = ''

        filter.values.forEach((value, index, values) => {
          filterValues += `${value}${(index !== values.length - 1 ? '--' : '')}`
        })

        this.params += `${filter.key}--${filterValues}${(index !== filters.length - 1 ? '_' : '')}`
      })

      // this.$router.replace({ path: `/${this.$route.params.slug}${this.params}` })
      this.$router.replace(this.localePath({
        name: 'slug-params',
        params: {
          slug: this.$route.params.slug,
          params: this.params
        }
      }))

      this.showOverlay()
    },

    getCategoryCount (category) {
      if (this.chosenFiltersLength > 0 && !this.chosenFilterValues.includes(category.title)) {
        return `+${category.count}`
      } else {
        return category.count
      }
    },

    toggleFiltersTouchScreen () {
      this.filtersTouchScreenActive = !this.filtersTouchScreenActive
    },

    hideSortOptions () {
      this.sort.active = false
    },

    async loadMoreProducts () {
      if (this.page >= this.promo.pages) {
        return
      }

      this.page += 1
      this.loading = true

      const badges = `badges--${this.promo.badges.map(b => b.slug).join('--')}` + (this.params.length ? '_' : '')

      await this.$axios.$get(`${this.$i18n.locale}/${this.country.iso_code}/products/${badges}${this.params}?page=${this.page}`)
        .then((res) => {
          this.reachedLastPage = this.page === this.promo.pages
          this.promo.products = [...this.promo.products, ...res.data]

          // history.replaceState({}, null, `${window.location.origin}${window.location.pathname}?page=${this.page}`)
        })
        .catch((e) => {
          console.log(e)
        })
        .finally(() => {
          this.loading = false
        })
    },

    showOverlay () {
      this.filtering = true
    }
  },

  head () {
    return {
      title: this.promo.page.title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('pages.search.description')
        }
      ]
    }
  }
}
