
import { mapGetters } from 'vuex'

import Breadcrumbs from '@/components/Breadcrumbs'
import General from '@/components/store/tabs/General'
import Reviews from '@/components/store/tabs/Reviews'
// import Products from '@/components/store/tabs/Products'

export default {
  components: {
    Breadcrumbs,
    General,
    Reviews
    // Products
  },

  props: {
    store: {
      type: Object,
      default () {
        return {}
      },
      required: true
    }
  },

  data () {
    return {
      breadcrumbs: [
        {
          title: this.$t('pages.index.title'),
          to: this.localePath('index'),
          active: false
        },
        {
          title: this.store.name,
          to: '#',
          active: true
        }
      ],
      tabs: {
        current: 'general',
        all: [
          {
            hash: 'general',
            name: 'general.title'
          },
          // {
          //   hash: 'products',
          //   name: 'products.title'
          // },
          {
            hash: 'reviews',
            name: 'reviews.title'
          }
        ]
      }
    }
  },

  computed: {
    ...mapGetters({
      country: 'country'
    })
  },

  mounted () {
    this.tabs.current = this.tabs.all.map(tab => tab.hash).includes(this.$route.query.tab)
      ? this.$route.query.tab
      : this.tabs.all[0].hash
  },

  methods: {
    toggleTab (tab) {
      this.tabs.current = tab

      history.replaceState({}, null, `${window.location.origin}${window.location.pathname}?tab=${tab}`)
    }
  }
}
