
import { mapGetters } from 'vuex'

import Breadcrumbs from '@/components/Breadcrumbs'
import JournalArticle from '@/components/index/JournalArticle'
import Trigger from '@/components/topic/Trigger'

export default {
  components: {
    Breadcrumbs,
    JournalArticle,
    Trigger
  },

  props: {
    topic: {
      type: Object,
      default () {
        return {}
      },
      required: true
    }
  },

  data: () => ({
    page: 1,
    loading: false
  }),

  computed: {
    ...mapGetters({
      country: 'country'
    }),

    breadcrumbs () {
      const length = this.topic.breadcrumbs.length

      this.topic.breadcrumbs.map((crumb, index) => {
        crumb.to = this.localePath({ name: 'slug', params: { slug: crumb.slug } })
        crumb.active = length === (index + 1)
      })

      return [
        {
          title: this.$t('pages.index.title'),
          to: this.localePath('index'),
          active: false
        }
      ].concat(this.topic.breadcrumbs)
    }
  },

  created () {
    this.page = this.$route.query.page ? Number(this.$route.query.page) : 1
  },

  methods: {
    async loadMoreArticles () {
      if (this.page >= this.topic.pages) {
        return
      }

      this.page += 1
      this.loading = true

      await this.$axios.$get(`${this.$i18n.locale}/${this.country.iso_code}/journal/topic/${this.topic.id}/articles?page=${this.page}`)
        .then((res) => {
          this.topic.articles = [...this.topic.articles, ...res.data]

          // history.replaceState({}, null, `${window.location.origin}${window.location.pathname}?page=${this.page}`)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
